import React, { useEffect, useState } from "react"
import { pageStore } from "state/store-zustand";


// Hook to handle resizing of an iframe
export const useIFrameResizer = ({
  containerSelector = '.container-resizable',
  containerRef = null,
  ...props
} = {}) => {

  // Did the page load with a resizer? See load.js
  const [hasResizer, setHasResizer] = useState(false)
  const [parent, setParent] = useState(null)


  // Get the height from the store
  const height = pageStore((state) => state.height)
  const isResizable = pageStore((state) => state.isResizable)
  const setHeight = pageStore((state) => state.setHeight)


  // isResizable, setHasResizer
  useEffect(() => {
    setHasResizer(isResizable)
  }, [isResizable])

  if (isResizable) {
    // When containerRef is set, set the height
    if (containerRef) {
      const heightContainer = containerRef?.current?.offsetHeight
      if (heightContainer && heightContainer != height) {
        if (heightContainer > 0 && heightContainer != height) {
          //console.log('DEBUG: setHeight ', heightContainer);
          setHeight(heightContainer)
        }
      }
    }
  }

  // Check if page is inside an iframe
  // Attach parentIFrame to window
  useEffect(() => {
    const isInIframe = window !== window.parent;
    const parentIFrame = typeof window !== 'undefined' ? window.parent : null
    setParent(parentIFrame)
    const hasParentObject = parentIFrame ? true : false
    console.log('DEBUG parentIFrame ', hasParentObject, parentIFrame);
    setHasResizer(hasParentObject, parentIFrame)
  }, [typeof window !== 'undefined' ? window.parentIFrame : undefined])


  // Check if container has resized
  useEffect(() => {
    let observer; // Declare observer outside the if block
    const containerEl = document.querySelector(containerSelector)
    console.log('DEBUG: containerEl ', containerEl, containerRef);

    if (containerEl) {
      // If element exist, set initial
      observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          console.log('DEBUG: ResizeObserver ', width, height);
          setHeight(height);
        }
      });
      try {
        observer.observe(containerEl);
      } catch (error) {
        console.log('Catch e ', error);
      }
    } else {
      console.log(`Element not found for selector: ${containerSelector}`);
    }

    return () => observer?.disconnect()
  }, [containerSelector])


  // Message size changes to parent
  const [lastSentMessage, setLastSentMessage] = useState(null)
  const [lastHeight, setLastHeight] = useState(null)

  useEffect(() => {
    const sendHeight = (height) => {
      console.log('DEBUG sendHeight ', window.parent, window.parent.postMessage)
      let parent = window?.parent
      //let isResizable = window?.parent?.isResizable
      if (height === lastSentMessage) return;

      const hasParent = parent ? true : false
      if (hasParent && parent.postMessage) {
        var height = height || document.body.scrollHeight;

        window.parent.postMessage(height, '*');
        setLastSentMessage(height) // Store the last sent message

      }
    }

    const checkHeight = (threshold = 50) => {
      var height = document.body.scrollHeight
      setLastHeight((prevLastHeight) => {
        const heightChanged = Math.abs(height - prevLastHeight) > threshold;

          if (height > 0 && heightChanged) {
          sendHeight(height)
          return height
        }
        return prevLastHeight;
      })
    }


    const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    const messageHandler = (e) => {
      const noData = isNaN(e.data)
      //console.log('DEBUG: messageHandler ', e.data, noData, 'parent ', parent, 'e ', e);
      if (noData) return;
      //sendHeight();
    }

    console.log('DEBUG height', height);
    if (height > 0 && height != lastHeight) {
      sendHeight(height);
      //console.log('DEBUG: isResizable, sendHeight ', isResizable, height);
    }

    setLastHeight(height)
    const intervalId = setInterval(checkHeight, 1000);

    // Listen for a message from the parent
    eventer(messageEvent, messageHandler, false);

    // Cleanup function to remove the event listener
    return () => {
      const removeEventMethod = window.removeEventListener ? "removeEventListener" : "detachEvent";
      const remover = window[removeEventMethod];
      remover(messageEvent, messageHandler, false);
      clearInterval(intervalId)
    }
  }, [parent, height])

  return [hasResizer, parent]
}

export default useIFrameResizer